import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import AmazonExperience from '../fiber-components/experience/Amazon';
import Bungalow12 from '../fiber-components/experience/Bungalow12';
import Moz from '../fiber-components/experience/Moz';
import Microsoft from '../fiber-components/experience/Microsoft';
import DigiPen from '../fiber-components/experience/Education';
import Volt from '../fiber-components/experience/Volt';
import Handheld from '../fiber-components/experience/Handheld';

interface ExperienceProps {
  showTitle?: boolean;
}

const Experience = ({showTitle}: ExperienceProps): JSX.Element => {
  return (
    <div className={'fill-most'}>
      {showTitle ? <h2 className={'section-title'}>Experience</h2> : null}
      <h2 className={'section-title'}>Professional Career</h2>
      <Tabs defaultActiveKey={'aws'}>
        <Tab eventKey={'aws'} title={'AWS (8/2018 - Current)'}>
          <AmazonExperience/>
        </Tab>
        <Tab eventKey={'b12'} title={'Bungalow 12 Studios (Ongoing)'}>

          <Bungalow12/>
        </Tab>
        <Tab eventKey={'moz'} title={'Moz (10/2014 - 6/2018)'}>
          <Moz/>
        </Tab>
        <Tab eventKey={'Microsoft'} title={'Microsoft (8/2008 - 9/2014)'}>
          <Microsoft/>
        </Tab>
        <Tab eventKey={'Volt'} title={'Volt (3/2008 - 8/2008)'}>
          <Volt/>
        </Tab>
        <Tab eventKey={'Handheld'} title={'Handheld Games (4/2007 - 3/2008)'}>
          <Handheld/>
        </Tab>
      </Tabs>
      <p><b><i>Click each entry for more details!</i></b></p>
      <h2 className={'section-title'}>Education</h2>
      <DigiPen/>
    </div>
  )
};

export default Experience;