import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';

import cats from '../static/images/Cats.png';
import gbnw from '../static/images/gbnw.png';
import ghost from '../static/images/ghost.png';
import spidey from '../static/images/Spidey.png';
import pups from '../static/images/Pups.png';
import tracy from '../static/images/Tracy.png';

interface HobbiesProps {
  showTitle?: boolean;
}

const Hobbies = ({showTitle}: HobbiesProps): JSX.Element => {
  return (
    <>
      {showTitle ? <h2 className={'section-title'}>Life and Hobbies</h2> : null}
      <Container>
        <Row>
          <Col>
            <Card style={{width: '30rem'}}>
              <Card.Img as={Image} variant={'top'} src={tracy}/>
              <Card.Body>
                <Card.Title>Tracy</Card.Title>
                <Card.Text>
                  My partner on this crazy road of life. Endlessly supportive and the most fun person in the world to adventure with.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{width: '30rem'}}>
              <Card.Img as={Image} variant={'top'} src={pups}/>
              <Card.Body>
                <Card.Title>Hope and Toby</Card.Title>
                <Card.Text>
                  These two sweet little angels are my girls. Their names are Toby (Golden Retriever) and Hope (Cattle Dog Mix).
                </Card.Text>
                <Card.Link href={'https://www.instagram.com/theadventuresofhopeandtoby/'}>TheAdventuresOfHopeAndToby</Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{width: '30rem'}}>
              <Card.Img as={Image} variant={'top'} src={cats}/>
              <Card.Body>
                <Card.Title>Parker and Kitty Pie</Card.Title>
                <Card.Text>
                  Parker is my little boy who has been with me since I first graduated college. He may be a grumpy old man but he has earned it.&nbsp;
                  Kitty Pie is my little angel. Extremely shy but the sweetest little cat in the world. I love them both with all my heart.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{width: '30rem'}}>
              <Card.Img as={Image} variant={'top'} src={gbnw} roundedCircle/>
              <Card.Body>
                <Card.Title>Ghostbusters NW</Card.Title>
                <Card.Text>
                  Ever since I was a kid I was a fan of the Ghostbusters. I've been extremely fortunate to meet one of the most like&nbsp;
                  minded individuals and together we have been working on my replica Proton Pack, going to events and filming amazing shorts.&nbsp;
                  Beau's brilliant writing and animation are absolutely incredible, as is the superb voice talent of fellow Ghostbuster Rob.
                </Card.Text>
                <Card.Link href={'https://ghostbustersnw.com'}>Homepage</Card.Link>
                <Card.Link href={'https://www.youtube.com/channel/UC-FZufitASQ0t51i0V9icig'}>YouTube</Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{width: '30rem'}}>
              <Card.Img as={Image} variant={'top'} src={ghost}/>
              <Card.Body>
                <Card.Title>Paranormal Research and Investigation</Card.Title>
                <Card.Text>
                  I guess this goes hand in hand with my love for the Ghostbusters. I have always had a fascination with the paranormal&nbsp;
                  and have taken to paranormal research, understanding the theories and mythologies and theoretical sciences behind&nbsp;
                  ghosts, portals, demons, psychic protection, etc...
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{width: '30rem'}}>
              <Card.Img as={Image} variant={'top'} src={spidey}/>
              <Card.Body>
                <Card.Title>Spider-Man</Card.Title>
                <Card.Text>
                  Ok so I am a huge comic book fan and occasional cosplayer. My favorite comic book character is the Amazing Spider-Man.&nbsp
                  I've always felt I related well to the character and let's be honest it is always fun to play the part of your heroes.
                </Card.Text>
                <Card.Link href={'https://goldenagecollectables.com/'}>Image of me from Golden Age Collectibles</Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default Hobbies;