import React from 'react';
import { Card, Container, Col, Row } from 'react-bootstrap';

import rogerPatrol from '../static/images/RogerPatrol.png'
import twinMaker from '../static/images/IotTwinMaker.png'
import builderBreaker from '../static/images/BuilderBreaker.png'

interface ProjectsProps {
  showTitle?: boolean;
}

const Projects = ({ showTitle }: ProjectsProps):JSX.Element => {
  return (
    <>
      {showTitle ? <h2 className={'section-title'}>Notable Projects</h2>: null}
      <Container>
        <Row>
          <Col>
            <Card style={{ width: '30rem' }}>
              <Card.Img variant={'top'} src={rogerPatrol}/>
              <Card.Body>
                <Card.Title>Roger Patrol</Card.Title>
                <Card.Text>
                  A game developed for MozCon 2016. Moz had always hoped to get developer involvement for the conference.
                  Once I found out that the theme for the upcoming year was 16-bit retro space ships I proposed the idea for the game.
                  The game is an asteroids style shooter designed to be played on an arcade machine, which was custom built for the event.
                  I was responsible for the game's design and development.
                </Card.Text>
                <Card.Link href={'https://github.com/Bungalow12/RogerPatrol'}>View on Github</Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '30rem' }}>
              <Card.Img variant={'top'} src={twinMaker}/>
              <Card.Body>
                <Card.Title>IoT TwinMaker 3D Scene Builder and Viewer</Card.Title>
                <Card.Text>
                  At AWS I am very proud to have been the Twin Maker teams 3D expert. I was given the opportunity to participate in the original
                  design and to be the builder of all the primary 3D features while I was a part of the team. I had the opportunity to teach
                  others 3D concepts and to continue to make an impact even while joining my next team.
                </Card.Text>
                <Card.Link href={'https://github.com/awslabs/iot-app-kit/tree/main/packages/scene-composer'}>View on Github</Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{ width: '30rem' }}>
              <Card.Img variant={'top'} src={builderBreaker}/>
              <Card.Body>
                <Card.Title>Builder Breaker</Card.Title>
                <Card.Text>
                  My first self-published game Builder Breaker was a <i>Match 3 Breakout hybrid</i>. Originally built in XNA and released
                  for the Windows Phone the game sadly no longer exists. The good news is that this is the first of the projects I currently
                  have in development. This version uses Unity and will be available across multiple platforms. Stay Tuned!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default Projects;