import React from 'react';
import { Accordion } from 'react-bootstrap';

const Microsoft = () => {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey={'surface'}>
          <Accordion.Header>Surface SDK (Table)</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              The original touch computing, the Surface Table! This was my first experience outside the game industry and I had gone to work as an SDET. &nbsp;
              My primary focus was on the Surface Table SDK written in WPF.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Helped build and tested the Scatter View (Picture) control</li>
                <li>Built applications for the platform as a means of proving the platform</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={'ie'}>
          <Accordion.Header>Windows/Internet Explorer</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              Building test tools, infrastructure, and test automation for Internet Explorer around the Windows 8 touch-first experience of the browser
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Built internal well received tools most notable a test machine setup utility</li>
                <li>Automation coverage for Tab view, Immersive Context Menu, input handling, site pinning and the early groundwork for chromium extensions</li>
                <li>Aided in maintaining legacy versions of IE</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Microsoft;