import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import './App.css';
import JonWillsRouter from './JonWillsRouter';

function App() {
  return (
    <div className="App">
      <JonWillsRouter/>
    </div>
  );
}

export default App;
