import React from 'react';
import { Accordion } from 'react-bootstrap';

const TwinMaker = () => {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey={'scene-composer'}>
          <Accordion.Header>3D Scene Composer</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              Perhaps one of the most interesting projects I've worked on. The Scene Composer is both a 3D scene&nbsp;
              builder and viewer. This is a React component that can be used from the&nbsp;
              <a href={'https://github.com/awslabs/iot-app-kit/'}>IoT App Kit</a> project available as open
              source.&nbsp;
              The component provides the user with a builder, in which assets uploaded to S3, can be loaded into a
              3D&nbsp;
              scene and then placed, broken down into sub pieces, annotated with utilities like motion indicators,
              tags&nbsp;
              color modifiers, and more. The user can also add multiple cameras for different viewing angles and&nbsp;
              camera settings. These scenes can then be viewed by inserting the component into a custom
              application&nbsp;
              or a <a href={'https://grafana.com/'}>Grafana</a> Dashboard or even view a scene in a collaborative&nbsp;
              feature with <a href={'https://matterport.com/'}>Matterport</a>.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Key member of the Architecture design team</li>
                <li>Project owner and leader of the overall Scene Composer</li>
                <li>Team 3D specialist</li>
                <li>Lead others in feature design and implementation</li>
                <li>Provided mentoring in 3D software development</li>
                <li>Lead best practice efforts for performant 3D</li>
                <li>Developer of the majority of key features</li>
                <ul>
                  <li>Tags</li>
                  <li>Cameras</li>
                  <li>3D cursor</li>
                  <li>Enhanced editing capabilities</li>
                  <li>And more</li>
                </ul>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={'unity'}>
          <Accordion.Header>Unity Editor Plugin</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              In order to support those that might want a more advanced and super user like editing experience we
              had&nbsp;
              worked on Editor plugins for Unity 3D Game Engine. These allowed users to create their scenes,&nbsp;
              upload their assets and set up the primary annotations through the Unity Editor. This project&nbsp;
              however, was not yet released.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Built on making professional Inspector integrations</li>
                <li>Built the export to AWS IoT TwinMaker feature</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default TwinMaker;