import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

interface AnnouncementstProps {
  showTitle?: boolean;
}

const Announcements = ({showTitle}: AnnouncementstProps) => {
  return (
    <div className={'fill-most'}>
      {showTitle ? <h2 className={'section-title'}>Announcements</h2> : null}
      <Accordion defaultActiveKey={'0'}>
        <Accordion.Item eventKey={'0'}>
          <Accordion.Header>New Experience - Placeholder Launch - 8/26/2023</Accordion.Header>
          <Accordion.Body>
            <div>
              <p>
                New placeholder web experience has been launched! I've wanted to create a more unique experience for my professional website for years.
                The hardest part is that I could never truly find the right way to express myself as a Real-time Interactive Simulation (Game) developer until now.
                With technologies like <i>React, ThreeJS and React Three Fiber</i> this has finally become possible.
              </p>
              <p>
                <b>NOTE: I apologize but for the moment the 3D experience is disabled for mobile. 😅</b>
              </p>
              <p>
                Now I know what you're thinking, "Jonathan a floating cube is not that exciting.";
                well yeah I can't argue there. However, this is the first step towards producing the web page I've always wanted.
                In an effort to get something up for the world to see, I threw this together as a promise for the exciting and unique experience to come.
              </p>
              <p>
                So stay tuned. In the coming months I hope to have a more exciting 3D experience that will not only show case what I can do but what I am passionate about.
              </p>
              <p>
                -Jonathan Wills
              </p>
              <p>
                For those of you that would prefer a more standard web experience click the link on the bottom of the page or <a href={'/standard'}>click here for a still
                version.</a>
              </p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Announcements;