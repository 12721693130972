import React, { JSX } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CubePage from './pages/CubePage';
import ClassicView from './pages/ClassicView';
import Announcements from './pages/Announcements';
import About from './pages/About';
import Experience from './pages/Experience';
import Projects from './pages/Projects';
import Hobbies from './pages/Hobbies';
import Links from './pages/Links';
import NotFound from './pages/NotFound';

const JonWillsRouter = (): JSX.Element => {
  const isMobile = window.navigator.userAgent.toLowerCase().includes('mobile');
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={!isMobile ? <CubePage/> : <ClassicView sectionName={'Announcements'} content={<Announcements/>}/>}/>
        {/*Below are the standard experience routes*/}
        <Route path={'/standard'} element={<ClassicView sectionName={'Announcements'} content={<Announcements/>}/>}/>
        <Route path={'/about'} element={<ClassicView sectionName={'About'} content={<About/>}/>}/>
        <Route path={'/experience'} element={<ClassicView sectionName={'Experience'} content={<Experience/>}/>}/>
        <Route path={'/projects'} element={<ClassicView sectionName={'Notable Projects'} content={<Projects/>}/>}/>
        <Route path={'/hobbies'} element={<ClassicView sectionName={'Life and Hobbies'} content={<Hobbies/>}/>}/>
        <Route path={'/links'} element={<ClassicView sectionName={'Links'} content={<Links/>}/>}/>
        <Route path={'*'} element={<ClassicView sectionName={'Nothing Here 😅'} content={<NotFound/>}/>}/>
      </Routes>
    </BrowserRouter>
  );
};

export default JonWillsRouter;