import React from 'react';
import { Accordion } from 'react-bootstrap';

const DigiPen = () => {
  return (
    <>
      <div>
        <b style={{fontSize: 20}}>DigiPen Institute of Technology (2003-2007)</b>
      </div>
      <div>
        <b>Bachelor of Science, Computer Science with emphasis on Real-time Interactive Simulation</b>
      </div>
      <div>
        <b>Overview</b>
      </div>
      <div>
        Learned C & C++ programming languages in great depth, game design, graphics programming, network development, artificial intelligence and physics simulation.
      </div>
      <Accordion defaultActiveKey={'projects'}>
        <Accordion.Item eventKey={'projects'}>
          <Accordion.Header>Projects</Accordion.Header>
          <Accordion.Body>
            <div>
              <ul>
                <li>3 completed game projects</li>
                <li>Cross platform socket library wrapped by a network manager interface</li>
                <ul>
                  <li>NACK based reliable UDP implementation</li>
                  <li>Cubic spline interpolation for smooth movement of 3D actors</li>
                </ul>
                <li>3D audio engine using SDL Mixer</li>
                <li>Created a Data driven scripting language and interpreter</li>
                <li>Focused D* (Dynamic A*) system</li>
                <li>CPU-based Ray Tracer</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default DigiPen;