import React from 'react';
import { Accordion } from 'react-bootstrap';

const Moz = () => {
  const rogerPatrolDescription = 'During MozCon 2016 I built a game in Unity 3D that had its own arcade machine for event goers to enjoy during breaks. ' +
    'Moz had always wanted developer involvement at MozCon. After seein the theme for the upcoming year, being a 16-bit space ship game ' +
    'I presented a plan to release a game that event goers could play that was similar to asteroids. This project was finally approved ' +
    'with only 4 months to be designed and implemented, along with my day to day work, this was a fun challenge that I am very proud of.'
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey={'roger-patrol'}>
          <Accordion.Header>Roger Patrol</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              {rogerPatrolDescription}
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Built a full game from scratch in 4 months in 25% of my work week</li>
                <li>Got the game running on laptops and custom arcade machines</li>
                <li>Lead my team of myself, a musician/tester/game balancing, and a few artists</li>
              </ul>
            </div>
            <div>
              <b>Features</b>
              <ul>
                <li>3 special powered up ships with heat seeking missiles, rainbow massive laser, and scattershot</li>
                <li>Scaling difficulty enemies</li>
                <li>Fully customizable stats for easy game balancing via Unity UI</li>
                <li>Target 5 minute maximum play time</li>
                <li>Many asteroids on screen breaking up into smaller asteroids with scaling concentration</li>
                <li>Leaderboards</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={'tools'}>
          <Accordion.Header>Internal tools and custom test harness</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              Built many internal tools used by multiple teams mostly for validation and testing. One key technology I improved, and written originally&nbsp;
              by a dear friend of mine, <a href={'https://github.com/emailit2me2/testaframe'}>Testaframe</a> is a test framework built on Selenium.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Mentored less experienced SDEs</li>
                <li>Mentored individuals from ADA Developer Academy</li>
                <li>Refactored testaframe making it easier to pick up and build with</li>
                <li>Aided in test design for non-existent coverage for the many products</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={'moz'}>
          <Accordion.Header>Moz.com</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              Worked with the Moz main homepage, CMS, user database, and sales funnel.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Incorporated the CMS system according to the requirements of the marketing team</li>
                <li>Worked with the management of users and the authorization system</li>
                <li>Maintained the legacy website written in PHP6</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Moz;