import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei'

import { Group, MathUtils, Vector3 } from 'three';
import About from '../pages/About';
import Announcements from '../pages/Announcements';
import Projects from '../pages/Projects';
import Experience from '../pages/Experience';
import Hobbies from '../pages/Hobbies';
import Links from '../pages/Links';

interface IWebCubeProps {
  size?: number
}

/**
 * 3D Cube with each face being a page from my placeholder professional website.
 * @param {number | undefined} size
 * @returns {React.JSX.Element}
 * @constructor
 */
const WebCube: React.FC<IWebCubeProps> = ({size}: IWebCubeProps) => {
  const cube = useRef<Group>();
  const scale = size ? new Vector3(size, size, size) : new Vector3(7, 7, 7);
  const pushOut = (scale.x / 2) + 0.01;
  const halfRotation = Math.PI / 2;
  const fullRotation = Math.PI;

  useFrame((state, delta) => {
    const t = state.clock.getElapsedTime()
    if (cube.current) {
      cube.current.rotation.x = MathUtils.lerp(cube.current.rotation.x, Math.cos(t / 2) / 20 + 0.15, 0.1)
      cube.current.rotation.y = MathUtils.lerp(cube.current.rotation.y, Math.sin(t / 4) / 20, 0.1)
      cube.current.rotation.z = MathUtils.lerp(cube.current.rotation.z, Math.sin(t / 8) / 20, 0.1)
      cube.current.position.y = MathUtils.lerp(cube.current.position.y, (-2 + Math.sin(t / 2)) / 2, 0.1)
    }
  })
  return (
    <group ref={cube as React.Ref<Group>}>
      <mesh>
        <boxGeometry args={scale.toArray()}/>
        <meshStandardMaterial color={'white'}/>
        <group name={'top'} position={[0, pushOut, 0]} rotation={[-halfRotation, 0, 0]}>
          <Html className={'content'} occlude transform>
            <div className={'wrapper'} onPointerDown={(e) => e.stopPropagation()}>
              <Links showTitle/>
            </div>
          </Html>
        </group>
        <group name={'bottom'} position={[0, -pushOut, 0]} rotation={[halfRotation, 0, 0]}>
          <Html className={'content'} occlude transform>
            <div className={'wrapper'} onPointerDown={(e) => e.stopPropagation()}>
              <Hobbies showTitle/>
            </div>
          </Html>
        </group>
        <group name={'left'} position={[-pushOut, 0, 0]} rotation={[0, -halfRotation, 0]}>
          <Html className={'content'} occlude transform>
            <div className={'wrapper'} onPointerDown={(e) => e.stopPropagation()}>
              <Projects showTitle/>
            </div>
          </Html>
        </group>
        <group name={'right'} position={[pushOut, 0, 0]} rotation={[0, halfRotation, 0]}>
          <Html className={'content'} occlude transform>
            <div className={'wrapper'} onPointerDown={(e) => e.stopPropagation()}>
              <About showTitle/>
            </div>
          </Html>
        </group>
        <group name={'front'} position={[0, 0, pushOut]} rotation={[0, 0, 0]}>
          <Html className={'content'} occlude transform>
            <div className={'wrapper'} onPointerDown={(e) => e.stopPropagation()}>
              <Announcements showTitle/>
            </div>
          </Html>
        </group>
        <group name={'back'} position={[0, 0, -pushOut]} rotation={[0, fullRotation, 0]}>
          <Html className={'content'} occlude transform>
            <div className={'wrapper'} onPointerDown={(e) => e.stopPropagation()}>
              <Experience showTitle/>
            </div>
          </Html>
        </group>
      </mesh>
    </group>
  )
};

export default WebCube;