import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import WebCube from '../fiber-components/WebCube';

const CubePage = (): JSX.Element => {
  const isMobile = window.screen.orientation.type === 'portrait-primary' || window.screen.orientation.type === 'portrait-secondary';
  const fov = isMobile ? 70 : 55;
  const titleClass = isMobile ? 'my-title-mobile' : 'my-title';
  const nameClass = isMobile ? 'my-name-mobile' : 'my-name';
  return (
    <div id={'canvas-container'}>
      <div className={'title'}>
        <div className={nameClass}>
          Jonathan Wills
          <p className={titleClass}>Real-time Interactive Simulated Software Development Engineer</p>
        </div>
      </div>
      <Canvas>
        <ambientLight intensity={0.3}/>
        <PerspectiveCamera makeDefault position={[0, 0, 15]} fov={fov}>
          <directionalLight intensity={1.5}/>
        </PerspectiveCamera>
        <OrbitControls enablePan={false} enableZoom={false} minPolarAngle={-Math.PI / 2} maxPolarAngle={Math.PI}/>
        <WebCube/>
      </Canvas>
      <div className={'foot-note'}>
        Prefer a standard web experience? <a href={'/standard'}>Click here!</a>
      </div>
    </div>
  );
};

export default CubePage;