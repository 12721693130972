import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import SecurityLake from './SecurityLake';
import TwinMaker from './TwinMaker';
import SiteWise from './SiteWise';

const AmazonExperience = () => {
  return (
    <>
      <h4 className={'section-title'}>Teams</h4>
      <Tabs defaultActiveKey={'security'}>
        <Tab eventKey={'security'} title={'Security Lake (12/2022 - Current)'}>
          <SecurityLake/>
        </Tab>
        <Tab eventKey={'twinmaker'} title={'IoT Twin Maker (10/2020 - 12/2022)'}>
          <TwinMaker/>
        </Tab>
        <Tab eventKey={'sitewise'} title={'IoT SiteWise (8/2018 - 10/2022)'}>
          <SiteWise/>
        </Tab>
      </Tabs>
    </>
  )
}

export default AmazonExperience;