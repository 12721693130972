import React from 'react';
import { Accordion } from 'react-bootstrap';

const SecurityLake = () => {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey={'tagging'}>
          <Accordion.Header>Tagging Feature</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              This feature provides users with standard AWS features for tagging resources created by the service for adding&nbsp;
              <a href={'https://docs.aws.amazon.com/IAM/latest/UserGuide/access_tags.html'}>Tag-Based Access Control</a>.
            </div>
            <div>
              <b>Responsibilities</b>
              <ul>
                <li>Guide the feature design</li>
                <li>Lead others into sub-feature design and implementation</li>
                <li>Build the API endpoints for the tagging feature for a global API surface</li>
                <li>Design and implement a back-fill of user resources that will not impact existing preview users</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={'ops'}>
          <Accordion.Header>Operational Excellence Improvements</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              Upon joining the team I learned that the majority of the team is new to Amazon with less than 2 years under their belt.&nbsp;
              I used my experience to help introduce some AWS best practices from successfully launched services to greatly improve&nbsp;
              the teams on-call efficiency and life along with overall quality of the service monitoring awareness.
            </div>
            <div>
              <b>Improvements</b>
              <ul>
                <li>Created internal tooling, that by default handles the complex account management and logging in</li>
                <li>Created several tools to improve on-call efficiency providing examples for others to create tools</li>
                <ul>
                  <li>This has lead to multiple tools being created by other engineers that also have given us more power to handle customer issues faster</li>
                </ul>
                <li>Educate the team on best practices surrounding on-call tasks and time management</li>
                <li>Set new standards that have greatly reduced the ticket count for the team and continues to do so</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
}

export default SecurityLake;