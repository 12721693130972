import React from 'react';
import { Accordion } from 'react-bootstrap';

const SiteWise = () => {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey={'apis'}>
          <Accordion.Header>Modeling Service</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              The SiteWise service relies on a modeling service for representing the different pieces of your assets.&nbsp;
              This is typically the first part of the service seen by customers.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Instrumental in infrastructure</li>
                <li>Built major endpoints for the API</li>
                <li>Interfaced and guided multiple spin off infrastructure teams</li>
                <li>Co-designed and built asynchronous scalable infrastructure utilizing a multitude of AWS Tools</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={'frontend'}>
          <Accordion.Header>Assisted in the development of the AWS Console front-end</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              The frontend for users to interface with IoT SiteWise via an intuitive web experience.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>One of the developers that worked full stack</li>
                <li>Built multiple aspects of the user experience</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default SiteWise;