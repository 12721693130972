import React from 'react';
import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface ClassicViewProps {
  sectionName: string,
  content: JSX.Element,
}

const ITEM_HEIGHT = 48;

const ClassicView = ({sectionName, content}: ClassicViewProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobile = window.navigator.userAgent.toLowerCase().includes('mobile');
  const titleClass = isMobile ? 'my-title-mobile' : 'my-title';
  const nameClass = isMobile ? 'my-name-mobile' : 'my-name';
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoAnnouncements = () => {
    window.location.href = '/standard';
    handleClose();
  }

  const gotoAbout = () => {
    window.location.href = '/about';
    handleClose();
  }

  const gotoExperience = () => {
    window.location.href = '/experience';
    handleClose();
  }

  const gotoProjects = () => {
    window.location.href = '/projects';
    handleClose();
  }

  const gotoHobbies = () => {
    window.location.href = '/hobbies';
    handleClose();
  }

  const gotoLinks = () => {
    window.location.href = '/links';
    handleClose();
  }

  const openEmail = () => {
    window.location.href = 'mailto:jonwills@jonwills.com';
    handleClose();
  }

  // @ts-ignore
  return (
    <>
      <div className={'classic-header'}>
        <div className={nameClass}>
          Jonathan Wills
          <div className={titleClass}>Real-time Interactive Simulated Software Development Engineer</div>
        </div>
      </div>

      <>
        <AppBar position="static" sx={{backgroundColor: '#323232'}}>
          <Toolbar>
            <IconButton
              id={'menu-button'}
              size={'large'}
              edge={'start'}
              color={'inherit'}
              aria-label={'menu'}
              sx={{mr: 2}}
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'menu-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 5,
                  width: '20ch',
                },
              }}
            >
              <MenuItem onClick={gotoAnnouncements}>
                Home
              </MenuItem>
              <MenuItem onClick={gotoAbout}>
                About
              </MenuItem>
              <MenuItem onClick={gotoExperience}>
                Experience
              </MenuItem>
              <MenuItem onClick={gotoProjects}>
                Notable Projects
              </MenuItem>
              <MenuItem onClick={gotoHobbies}>
                Life and Hobbies
              </MenuItem>
              <MenuItem onClick={gotoLinks}>
                Links
              </MenuItem>
            </Menu>
            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
              {sectionName}
            </Typography>
            <Button color="inherit" onClick={openEmail}>Contact</Button>
          </Toolbar>
        </AppBar>
        <div className={'page-content'}>
          {content}
        </div>
      </>
    </>
  );
};

export default ClassicView;