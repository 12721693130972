import React from 'react';
import { Accordion } from 'react-bootstrap';

const Handheld = () => {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey={'coaster'}>
          <Accordion.Header>Crazy Coaster</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              Think Theme Park x Roller Coaster Tycoon in 2D on a TV plug and play device. Built on the SPG240 chipset.&nbsp;
              This although fully developed never saw the light of day.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Engine updates to handle transparency on a device that did not handle transparency natively</li>
                <li>Menu System</li>
                <li>Park visitor AI</li>
                <li>First-person roller coaster riding experience</li>
                <li>Economy and Management system</li>
                <li>Trophy System</li>
                <li>Roller coaster rating system</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={'engine'}>
          <Accordion.Header>Nintendo DS In-house Game Engine</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              Handheld Games used an internally built 3D game engine for the Nintendo DS. I was a key contributor on the project.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Developed the 3D physics system</li>
                <li>Built Single-card download play</li>
                <li>Multi-card wireless play</li>
                <li>Nintendo Online Wi-Fi play</li>
                <li>Network system used for The Cheetah Girls - Passport to Stardom</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Handheld;