import React from 'react';
import { Stack } from 'react-bootstrap';

import photo from '../static/images/Me.png';

interface AboutProps {
  showTitle?: boolean;
}

const About = ({ showTitle }: AboutProps): JSX.Element => {
  return (
    <div className={'fill-max'} id={'about'}>
      {showTitle ? <h2 className={'section-title'}>About</h2> : null}
      <div>
        <img className={'wrap'} src={photo} alt={'Jonathan Wills photo'} width={'256'}/>
        <p>
          My name is Jonathan Wills. I'm a widely varied software development engineer with a degree in <i>Real-time Interactive Simulation</i> from &nbsp;
          <b>DigiPen Institute of Technology</b>, for those that don't know that is a fancy way of saying <i>Game Development</i> or in the modern &nbsp;
          world any 3D software you expect to respond instantly rather than wait for rendering.
        </p>
        <p>
          I've had a very varied career however, I was a game developer as an indie studio and worked primarily on the internal <b>Nintendo DS</b> game engine &nbsp;
          as well as <b>TV plug and play devices <i>(super limited hardware)</i></b>. After that I worked at Microsoft on the <b>Surface Table</b> working &nbsp;
          with the SDK before being re-org'd into <b>Windows</b> and then later <b>Internet Explorer</b>. After that, I moved into an amazing company <b>Moz</b>, &nbsp;
          whom worked in SEO. While there I was a tools engineer, full stack web developer and, believe it or not, a game developer! &nbsp;
          <b>(See: <a href={'https://github.com/Bungalow12/RogerPatrol'}>Roger Patrol</a></b>. Then finally I went to learn how to scale that skill as well as &nbsp;
          get back to my roots at <b>Amazon Web Services (AWS)</b>. While there I launched 4 services! <b>IoT SiteWise Preview, IoT SiteWise, IoT TwinMaker &nbsp;
          <i>(the 3D Scene Builder and Viewer for Digital Twins)</i> and Security Lake.</b>
        </p>
        <p>
          I'm grateful for the experiences I've had and the broad skills I've learned from working with some incredibly smart and talented people. &nbsp;
          I am always looking forward to the future to learn more, build more and grow especially in my passion of <b>Real-time Interactive Simulated Software</b>.
        </p>
      </div>
    </div>
  )
};

export default About;