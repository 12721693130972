import React from 'react';
import { Accordion } from 'react-bootstrap';

const Volt = () => {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey={'surface'}>
          <Accordion.Header>Surface SDK (Table)</Accordion.Header>
          <Accordion.Body>
            <div>
              <b>Overview</b>
            </div>
            <div>
              The original touch computing, the Surface Table! This was my first experience outside the game industry and I had gone to work as an SDET. &nbsp;
              Although a more SDE than test this had moved my career away from my original chosen path.
            </div>
            <div>
              <b>Accomplishments</b>
              <ul>
                <li>Built tools for the SDET team for the Surface Table</li>
                <li>Moved to full-time in 8 months</li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Volt;