import React from 'react';
import { Accordion } from 'react-bootstrap';

const Bungalow12 = () => {
  return (
    <>
      <div>
        <p>
          Bungalow 12 Studios is my personal game development moniker. Built because of my love for game development and continued learning&nbsp;
          even though I no longer am a part of the game industry.
        </p>
        <p>
          These games are all designed and built solely by me for learning and hopefully some extra funds to continue building games as a hobby.&nbsp;
          I have a lot of ideas I can't wait to share with the world while learning different game technologies and skills.
        </p>
      </div>
      <Accordion>
        <Accordion.Item eventKey={'builder-breaker-wp'}>
          <Accordion.Header>Builder Breaker (Windows Phone)</Accordion.Header>
          <Accordion.Body>
            <div>
              The original version of my first official personal released game. This match 3/breakout hybrid was a fast based puzzle game with&nbsp;
              upgradable power ups, a leaderboard system, achievements, and in-app purchases. Sadly, this game is no longer available due to the store shutting down.
            </div>
            <b>Technologies Used</b>
            <ul>
              <li>Microsoft XNA</li>
              <li>3rd-party online game-system</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={'builder-breaker'}>
          <Accordion.Header>Builder Breaker (In progress)</Accordion.Header>
          <Accordion.Body>
            <div>
              A complete re-make of the original for modern platforms and possibly a console or two. It will be released with all of the&nbsp;
              original features and more. This time the backend will be completely custom.
            </div>
            <b>Technologies Used</b>
            <ul>
              <li>Unity 3D</li>
              <li>Custom online game platform (B12 Gaming Platform)</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={'gaming-platform'}>
          <Accordion.Header>B12 Gaming Platform</Accordion.Header>
          <Accordion.Body>
            <div>
              Cross-product game platform allowing for cross-game unlocks and benefits, online storage of in-game stats, leaderboards, achievements and more...
            </div>
            <b>Technologies Used</b>
            <ul>
              <li>AWS API Gateway</li>
              <li>AWS Lambda</li>
              <li>DynamoDB</li>
              <li>AWS Cognito</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Bungalow12;