import React from 'react';
import { Card, Stack } from 'react-bootstrap';

interface LinksProps {
  showTitle?: boolean;
}

const Links = ({showTitle}: LinksProps): JSX.Element => {
  return (
    <>
      {showTitle ? <h2 className={'section-title'}>Links</h2> : null}
      <div style={{width: '100%!important'}}>
        <Stack gap={3} style={{alignItems: 'center'}}>
          <div>
            <Card style={{width: '30rem'}}>
              <Card.Title>Email me</Card.Title>
              <Card.Body>
                Looking to get in touch?
              </Card.Body>
              <Card.Link className={'link-primary'} href={'mailto:jonwills@jonwills.com'}>jonwills(at)jonwills.com</Card.Link>
            </Card>
            <Card style={{width: '30rem'}}>
              <Card.Title>LinkedIn</Card.Title>
              <Card.Body>
                Looking to connect?
              </Card.Body>
              <Card.Link className={'link-primary'} href={'https://www.linkedin.com/in/jonathanwillsdeveloper/'}>JonathanWillsDeveloper on LinkedIn</Card.Link>
            </Card>
            <Card style={{width: '30rem'}}>
              <Card.Title>Resume</Card.Title>
              <Card.Body>
                Want to download a copy of my resume?
              </Card.Body>
              <Card.Link className={'link-primary'} href={'https://drive.google.com/file/d/1Q1RJGxATtwYk5NKMJY8gBU5_m_ut0kvP/view?usp=sharing'}>
                Download
              </Card.Link>
            </Card>
          </div>
        </Stack>
      </div>
    </>
  )
};

export default Links;